import React from 'react'
import HeaderContent from './HeaderContent.js';
import Portfolio from './Portfolio.js';
import About from './About.js';
import Contact from './Contact.js';

function Home() {
    return (
        <div className='app--content'>
            <HeaderContent/>
            <Portfolio/>
            <About/>
            <Contact/>
        </div>
    )
}

export default Home
