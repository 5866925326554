import React from 'react'
import './ShopItem.css'
import {useState} from "react"
import PayPalButtons from "./PayPalButtons.js"

function ShopItem(props) {
    const [payment, setPayment] = useState(0);

    const makePayment = () =>{
        setPayment(payment+1);
    }
    const closeMakePayment = () =>{
        setPayment(payment-1);
    }

    return (
        <div className='shopitem' style={{flexDirection: props.direction, borderTopColor: props.hexcol}}>
                <div className="shopitem--img" 
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + props.shopitem__image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                </div>
                {payment === 0 ?
                    <div className="shopitem--info">
                        <h2  style={{color: props.hexcol}} className='price'>{props.idItem}</h2>
                        <h2 className='product--title'>{props.product}</h2>
                        <p className='product--desc'>{props.desc}</p>
                        <div className="buybtn--container">
                            <button className='shopitem--buybtn'  style={{color: props.hexcol, borderColor: props.hexcol}}>
                                <a onClick={() => makePayment()} className="buy__btn">BUY</a>
                            </button>
                        </div>
                    </div>:
                    <div className="shopitem--info">
                        <h2 style={{color: props.hexcol}} className='price'>{props.idItem}</h2>
                        <h2 className='product--title' style={{paddingBottom:"20%"}} >{props.product}</h2>
                        <PayPalButtons access__link={props.access__link} price={props.price}/>
                        <div className="buybtn--container">
                            <button className='shopitem--buybtn' style={{color: props.hexcol, borderColor: props.hexcol}}>
                                <a onClick={() => closeMakePayment()} className="buy__btn">BACK</a>   
                            </button>
                        </div>
                    </div>
                }
        </div>
    )

}

export default ShopItem
