import React from "react";
import "./NavBar.css";
function NavBar() {
  return (
    <div className="navbar">
      <div className="logo--section">
        <a href="/">
          <img className="logo" alt="logo" src={"/logo.png"}></img>
        </a>
        <a className="logo--section--name" href="/">
          Alex
        </a>
        <p className="logo--section--info">Photographer + VCE Tutor</p>
      </div>
      <div className="nav">
        <a href="/store" className="nav--item">
          Store
        </a>
      </div>
    </div>
  );
}

export default NavBar;
