import React from 'react'
import "./HeaderContent.css"

function HeaderContent() {

    return (
        <div className="header--content">
          <div className="css-typing">
            <h1 className="intro--header--top"> Hi,</h1>
            <h1 className="intro--header"> I'm Alex,</h1>
            <h1 className="intro--header"> Photographer + Tutor</h1>
          </div>
            <p>Photographer / Lightroom Expert / VCE Tutor</p>
            <button className='header--content--btn'>
                    <a href="#contact">Contact Me!</a>
            </button>        
          </div>
    )
}

export default HeaderContent
