import React from "react";
import "./App.css";
import NavBar from "./NavBar.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home.js";
import Shop from "./Shop.js";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store" element={<Shop />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
