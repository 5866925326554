import React from 'react';

function Skills() {
  return( 
        <div className="skills--swirl">

        </div>
    );
}

export default Skills;
