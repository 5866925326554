import React from 'react';

function PortfolioImage(props) {
  return    <div className='portfolio__image'
                style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + props.portfolio__image})`,
                    }}>
            </div>
}

export default PortfolioImage;
